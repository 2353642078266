import { render, staticRenderFns } from "./NewSiteZZ.vue?vue&type=template&id=b33e0edc&scoped=true"
import script from "./NewSiteZZ.vue?vue&type=script&lang=js"
export * from "./NewSiteZZ.vue?vue&type=script&lang=js"
import style0 from "./NewSiteZZ.vue?vue&type=style&index=0&id=b33e0edc&prod&lang=less&scoped=true"
import style1 from "./NewSiteZZ.vue?vue&type=style&index=1&id=b33e0edc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b33e0edc",
  null
  
)

export default component.exports