<template>
  <div class="newSiteWarpZZ">
    <!-- <van-nav-bar left-arrow title="新建站点" left-text="返回" @click-left="$router.go(-1)"></van-nav-bar> -->
    <step :stepArr="stepArr" :stepFull="true"></step>
    <div>
      <div class="title">基本信息</div>
      <van-field :formatter="nameFormatter"
                 maxLength="20"
                 v-model="masterInfo.name"
                 label="姓名"
                 placeholder="请输入姓名"></van-field>
      <van-field :formatter="IdFormatter"
                 maxLength="18"
                 v-model="masterInfo.idCard"
                 label="身份证号"
                 placeholder="请输入身份证号"
                 @blur="IDNOBlur"
                 @click-right-icon.stop="rightChooseImg"
                 right-icon="photograph">
      </van-field>
      <van-uploader :preview-image="false"
                    ref="cameraImg"
                    style="display:none;"
                    capture='camera'
                    v-model="fileList"
                    :after-read="afterRead" />
      <van-field :formatter="phoneFormatter"
                 maxLength="11"
                 v-model="masterInfo.phoneNo"
                 label="电话"
                 placeholder="请输入电话号码"
                 @focus="focusInput"></van-field>

      <div class="mt2"></div>
      <van-cell title="是否为本地户口">
        <van-radio-group class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.isLocalResidence">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="是否在本村居住">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.liveVillage">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="是否为党员">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.partyMember">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell title="是否为村干部">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.villageCadre">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>

      <div class="mt2"></div>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.education"
                 label="教育状况"
                 placeholder="请选择学历"
                 @click="selectClick('education', '教育状况')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.marital"
                 label="婚姻状况"
                 placeholder="请选择婚姻状况"
                 @click="selectClick('marital', '婚姻状况')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.licenseQualify"
                 label="营业执照资质"
                 placeholder="请选择相关资质"
                 @click="selectClick('licenseQualify', '营业执照资质')"
                 right-icon="arrow-down"></van-field>
      <van-field @focus="focusInput"
                 v-model="masterInfo.professionHistory"
                 label="职业经历"
                 placeholder="请输入职业经历(选填)"
                 maxlength="10"></van-field>

      <div class="title">家庭信息</div>
      <van-field :formatter="nameFormatter"
                 maxLength="20"
                 v-model="masterInfo.linkmanName"
                 label="家属姓名"
                 placeholder="请输入家属姓名"
                 @focus="focusInput"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.linkmanRelation"
                 label="家属关系"
                 placeholder="请选择家属关系"
                 @click="selectClick('linkmanRelation', '家属关系')"
                 right-icon="arrow-down"
                 visible-item-coun="0"></van-field>
      <van-field maxLength="11"
                 :formatter="phoneFormatter"
                 v-model="masterInfo.linkmanPhone"
                 label="家属电话"
                 placeholder="请输入家属电话"
                 @focus="focusInput"></van-field>

      <div class="mt2"></div>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.familyAssets"
                 label="家庭总资产"
                 placeholder="请选择资产范围"
                 @click="selectClick('familyAssets', '家庭总资产')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.familyAnnualIncome"
                 label="家庭年收入"
                 placeholder="请选择收入范围"
                 @click="selectClick('familyAnnualIncome', '家庭年收入')"
                 right-icon="arrow-down"></van-field>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.familyIncomeSource"
                 label="家庭收入来源"
                 placeholder="请选择家庭收入来源"
                 @click="selectClick('familyIncomeSource', '家庭收入来源')"
                 right-icon="arrow-down"
                 visible-item-coun="0"></van-field>

      <div class="mt2"></div>
      <van-cell title="家属在村委、乡镇任职">
        <van-radio-group checked-color="#3F7C53"
                         class="nativeRadio"
                         direction="horizontal"
                         v-model="masterInfo.relaInVillageOffice">
          <van-radio name="1" @click="focusInput" :disabled="!masterInfo.idCard">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio name="0" @click="focusInput" :disabled="!masterInfo.idCard">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </van-cell>
      <van-field clickable
                 readonly
                 v-model="pageInfoShowVal.headOfCivil"
                 label-width="4rem"
                 label="家属任民间组织负责人"
                 placeholder="请选择"
                 @click="selectClick('headOfCivil', '家属任民间组织负责人')"
                 right-icon="arrow-down"
                 visible-item-coun="0"></van-field>

      <div class="title">其它信息</div>
      <van-checkbox-group v-model="masterInfo.otherInfo"
                          class="otherRadio">
        <van-checkbox @click="focusInput"
                      checked-color="#3F7C53"
                      :disabled="!masterInfo.idCard"
                      v-for="(item,index) in selectList.otherInfo"
                      :name="item.dictKey"
                      :key="index">{{item.dictValue}}</van-checkbox>
      </van-checkbox-group>

      <div class="title">重点调查事项（必选） </div>
      <div class="pl15">
        <p>是否有非法集资、民间借贷等行为</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isRaise">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
        <p>是否有公安机关不良记录或犯罪记录</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isCrime">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
        <p>是否参与赌博活动</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isBet">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
        <p>是否有不良征信记录</p>
        <van-radio-group checked-color="#3F7C53"
                         class="startRadio"
                         v-model="pageInfoShowVal.isBadCredit">
          <van-radio :name="true">是
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
          <van-radio :name="false">否 
            <template #icon="props">
              <img class="img-icon" :src="props.checked ? checked : unchecked" />
            </template>
          </van-radio>
        </van-radio-group>
      </div>

    </div>

    <van-popup v-model="popupShow"
               v-if="popupShow"
               position="bottom">
      <van-picker show-toolbar
                  :title="popuptitle"
                  :columns="popupList"
                  @cancel="popupShow = false"
                  @confirm="popupConfirm" />
    </van-popup>

    <div class="nextBtn">
      <div class="btn-active"
           @click="btnAction">下一步</div>
    </div>

  </div>
</template>

<script>
import common from '@/utils/common'
import Step from '@/components/Step'
import { saveMasterInfo, getDictByPage, getMasterInfoByIdCard, getMasterInfoByNo, getIdCardExist, idCardOcrFront } from '@/utils/api'
import {uwStatisticAction} from "../../utils/util";

export default {
  name: "NewSiteZZ",
  components: {
    Step
  },
  data () {
    return {
      stepArr: [
        { name: "村情信息", isActive: true },
        { name: "站长信息", isActive: true }
      ],
      // fFJZ: null,
      // badRecord: null,
      // gamb: null,
      // badCredit: null,
      popupVal: '',
      popupShow: false,
      popupList: [],
      popuptitle: '',
      selectList: [],
      tempObj: null,
      pageInfoShowVal: {
        education: null,
        marital: null,  
        licenseQualify: null,
        linkmanRelation: null,
        familyAssets: null,
        familyAnnualIncome: null,
        familyIncomeSource: null,
        headOfCivil: null,
    
        isRaise: null,
        isCrime: null,
        isBet: null,
        isBadCredit: null
        
      },
      popupListArr: [],
      masterInfo: {
        name: null,
        idCard: null,
        phoneNo: null,
        isLocalResidence: null,
        liveVillage: null,
        partyMember: null,
        villageCadre: null,
        relaInVillageOffice: null,
        education: '',
        marital: '',
        licenseQualify: '',
        professionHistory: '',
        linkmanName: null,
        linkmanRelation: '',
        linkmanPhone: '',
        familyAssets: '',
        familyAnnualIncome: '',
        familyIncomeSource: '',
        headOfCivil: '',
        otherInfo: [],
      },
      oldIDCard: '',
      fileList: [],
      filename: '',
      checked: require('@/assets/img/site/checked.png'),
      unchecked: require('@/assets/img/site/unchecked.png'),
    }
  },
  watch:{
    masterInfo:{
      handler(val){
        if(!common.getItem(common.getItem('uniqueNo'))) return
        if(val){
          let oldValue = common.getItem(common.getItem('uniqueNo')).sitezz ?common.getItem(common.getItem('uniqueNo')).sitezz:{}
          oldValue.name = val.name
          oldValue.idCard = val.idCard
          oldValue.phoneNo = val.phoneNo
          oldValue.isLocalResidence = val.isLocalResidence
          oldValue.liveVillage = val.liveVillage
          oldValue.partyMember = val.partyMember
          oldValue.villageCadre = val.villageCadre
          oldValue.relaInVillageOffice = val.relaInVillageOffice
          oldValue.education = val.education
          oldValue.marital = val.marital
          oldValue.licenseQualify = val.licenseQualify
          oldValue.professionHistory = val.professionHistory
          oldValue.linkmanName = val.linkmanName
          oldValue.linkmanRelation = val.linkmanRelation
          oldValue.linkmanPhone = val.linkmanPhone
          oldValue.familyAssets = val.familyAssets
          oldValue.familyAnnualIncome = val.familyAnnualIncome
          oldValue.familyIncomeSource = val.familyIncomeSource
          oldValue.headOfCivil = val.headOfCivil
          oldValue.otherInfo = val.otherInfo

          let cacheObj = common.getItem(common.getItem('uniqueNo'))
          cacheObj.sitezz = oldValue 
          common.setItem(common.getItem('uniqueNo'), cacheObj)
        }
      },
      deep:true
    },
    pageInfoShowVal:{
      handler(val){
        if(!common.getItem(common.getItem('uniqueNo'))) return
        let oldValue = common.getItem(common.getItem('uniqueNo')).sitezzShow ?common.getItem(common.getItem('uniqueNo')).sitezzShow:{}
        if(val){
          oldValue.education = val.education
          oldValue.marital = val.marital
          oldValue.licenseQualify = val.licenseQualify
          oldValue.linkmanRelation = val.linkmanRelation
          oldValue.familyAssets = val.familyAssets
          oldValue.familyAnnualIncome = val.familyAnnualIncome
          oldValue.familyIncomeSource = val.familyIncomeSource
          oldValue.headOfCivil = val.headOfCivil
          oldValue.isRaise = val.isRaise
          oldValue.isCrime = val.isCrime
          oldValue.isBet = val.isBet
          oldValue.isBadCredit = val.isBadCredit
          let cacheObj = common.getItem(common.getItem('uniqueNo'))
          cacheObj.sitezzShow =  oldValue
          common.setItem(common.getItem('uniqueNo'), cacheObj)
        }
      },
      deep: true
    }
  },
  mounted () {
    if(common.getItem('jumpFrom') != undefined){
      if(common.getItem(common.getItem('uniqueNo')) ){
        this.getCache()
      }else{
        let key = common.getItem('uniqueNo')
        let value = {
          sitecq: {},
          sitecqShow: {},
          sitezz: {},
          sitezzShow: {}
        }
        common.setItem(key, value)
      }
    }else{
      if(common.getItem(common.getItem('uniqueNo'))){
        this.getCache()
      }
    }
   

    getDictByPage('masterPage').then(res => {
      if (res.code === 200) {
        let data
        if (res.encryptedStatus === '1') {
          data = JSON.parse(common.decrypt(res.data, res.timestamp));
        } else {
          data = res.data
        }
        if (data.businessCode === 200) {
          let json = JSON.parse(data.data)
          this.selectList = json

          // 以防有时候返回参数不显示
          if (this.$route.query && this.$route.query.idCard && this.$route.query.idCard != 'null') {
            this.masterInfo.idCard = this.$route.query.idCard
            this.queryPageInfo()
          }
          if (this.$route.query.stationNo) {
            this.getMasterInfo();
          }
        }
      }
    });
    uwStatisticAction('/newsSiteZhanzhang','新建站点站长填写')
  },
  methods: {
    getCache() {
      let val = common.getItem(common.getItem('uniqueNo')).sitezz ?common.getItem(common.getItem('uniqueNo')).sitezz:{}
      if(val){
        this.masterInfo.name = val.name
        this.masterInfo.idCard = val.idCard
        this.masterInfo.phoneNo = val.phoneNo
        this.masterInfo.isLocalResidence = val.isLocalResidence
        this.masterInfo.liveVillage = val.liveVillage
        this.masterInfo.partyMember = val.partyMember
        this.masterInfo.villageCadre = val.villageCadre
        this.masterInfo.relaInVillageOffice = val.relaInVillageOffice
        this.masterInfo.education = val.education
        this.masterInfo.marital = val.marital
        this.masterInfo.licenseQualify = val.licenseQualify
        this.masterInfo.professionHistory = val.professionHistory
        this.masterInfo.linkmanName = val.linkmanName
        this.masterInfo.linkmanRelation = val.linkmanRelation
        this.masterInfo.linkmanPhone = val.linkmanPhone
        this.masterInfo.familyAssets = val.familyAssets
        this.masterInfo.familyAnnualIncome = val.familyAnnualIncome
        this.masterInfo.familyIncomeSource = val.familyIncomeSource
        this.masterInfo.headOfCivil = val.headOfCivil
        this.masterInfo.otherInfo = val.otherInfo
      }
      let valShow = common.getItem(common.getItem('uniqueNo')).sitezzShow ?common.getItem(common.getItem('uniqueNo')).sitezzShow:{}
      if(valShow){
        this.pageInfoShowVal.education = valShow.education
        this.pageInfoShowVal.marital = valShow.marital
        this.pageInfoShowVal.licenseQualify = valShow.licenseQualify
        this.pageInfoShowVal.linkmanRelation = valShow.linkmanRelation
        this.pageInfoShowVal.familyAssets = valShow.familyAssets
        this.pageInfoShowVal.familyAnnualIncome = valShow.familyAnnualIncome
        this.pageInfoShowVal.familyIncomeSource = valShow.familyIncomeSource
        this.pageInfoShowVal.headOfCivil = valShow.headOfCivil
        this.pageInfoShowVal.isRaise = valShow.isRaise
        this.pageInfoShowVal.isRaise = valShow.isRaise
        this.pageInfoShowVal.isCrime = valShow.isCrime
        this.pageInfoShowVal.isBet = valShow.isBet
        this.pageInfoShowVal.isBadCredit = valShow.isBadCredit
      }
    },
    rightChooseImg () {
      this.$refs.cameraImg.chooseFile()
    },
    afterRead (file) {
      console.log(file)
      // 大于15M
      if (file.file.size > 15 * 1024 * 1024) {
        Toast('图片太大，请上传小于15M的图片')
        return
      }
      if (!common.imageVerify(file.file.type)) {
        this.fileList.splice(detail.index, 1)
        Toast('上传图片必须为png或者jpg格式')
        return
      }
      // 小于100K不进行压缩
      if (file.file.size < 100 * 1024) {
        this.saveFile(file.content)
        return
      }
      this.filename = file.file.name
        ? file.file.name
        : "defaultname";
      let reader = new FileReader()
      reader.readAsDataURL(file.file)
      let _this = this
      reader.onload = function (e) {
        let content = file.content //图片的src，base64格式
        let img = new Image()
        img.src = content
        img.onload = function () {
          common.compress(img, (dataURL) => {
            _this.saveFile(dataURL)
          })
        }
      }
    },
    saveFile (dataURL) {
      let params = {
        fileContent: dataURL
      }
        idCardOcrFront(params).then(res => {
        if (res.code === 200) {
          if (res.data.businessCode === 200) {
           this.masterInfo.idCard = res.data.idCard
            this.masterInfo.name = res.data.userName
          }
        }
      })
    },
    IDNOBlur () {
      if (!this.masterInfo.idCard) {
        return
      }
      if (!common.IdCardVerify(this.masterInfo.idCard)) {
        this.$toast('站长身份证格式不正确')
        return
      }
      // 先不管回显
      this.queryPageInfo()
    },
    nameFormatter (val) {
      return val.replace(/[^\u4e00-\u9fa5.]/g, '')
    },
    IdFormatter (val) {
      return val.replace(/[^\w.v]/ig, "")
    },
    phoneFormatter (val) {
      if (!this.masterInfo.idCard) {
        return ''
      }
      return val.replace(/[^\d]/g, "")
    },
    focusInput () {
      if (!this.masterInfo.idCard) {
        this.$toast('请先输入身份证号')
        return
      }
    },
    selectClick (obj, title) {
      if (!this.masterInfo.idCard) {
        this.$toast('请先输入身份证号')
        return
      }
      this.popupShow = true
      this.popupList = []
      this.popuptitle = title
      for (let obj of this.selectList[obj]) {
        this.popupList.push(obj.dictValue)
      }
      this.popupListArr = this.selectList[obj]
      this.tempObj = obj
    },
    popupConfirm (value, index) {
      this.popupShow = false
      this.pageInfoShowVal[this.tempObj] = value
      this.masterInfo[this.tempObj] = this.popupListArr[index].dictKey
    },
    btnAction () {
      if (!this.masterInfo.name) {
        this.$toast('请输入站长姓名')
        return
      }
      if (!this.masterInfo.idCard) {
        this.$toast('请输入站长身份证号')
        return
      }
      if (this.masterInfo.idCard.length < 15) {
        this.$toast('站长身份证格式不正确')
        return
      }
      if (!common.IdCardVerify(this.masterInfo.idCard)) {
        this.$toast('站长身份证格式不正确')
        return
      }
      if (!this.masterInfo.phoneNo) {
        this.$toast('请输入站长电话')
        return
      }
      if (!common.phoneVerify(this.masterInfo.phoneNo.replace(/\s+/g, ""))) {
        this.$toast('站长电话格式不正确')
        return
      }
      if (!this.masterInfo.isLocalResidence) {
        this.$toast('请选择站长是否为本地户口')
        return
      }
      if (!this.masterInfo.liveVillage) {
        this.$toast('请选择站长是否在本村居住')
        return
      }
      if (!this.masterInfo.partyMember) {
        this.$toast('请选择站长是否为党员')
        return
      }
      if (!this.masterInfo.villageCadre) {
        this.$toast('请选择站长是否为村干部')
        return
      }
      if (!this.masterInfo.education) {
        this.$toast('请选择站长教育状况')
        return
      }
      if (!this.masterInfo.marital) {
        this.$toast('请选择站长婚姻状况')
        return
      }
      if (!this.masterInfo.licenseQualify) {
        this.$toast('请选择站长营业执照资质')
        return
      }
      if (this.masterInfo.professionHistory && (this.masterInfo.professionHistory.length < 4 || this.masterInfo.professionHistory.length > 10)) {
        this.$toast('请输入4-10字的职业经历')
        return
      }
      if (!this.masterInfo.linkmanName) {
        this.$toast('请输入家属姓名')
        return
      }
      if (!this.masterInfo.linkmanRelation) {
        this.$toast('请选择家属关系')
        return
      }
      if (!this.masterInfo.linkmanPhone) {
        this.$toast('请输入家属电话')
        return
      }
      if (!common.phoneVerify(this.masterInfo.linkmanPhone.replace(/\s+/g, ""))) {
        this.$toast('家属电话格式不正确')
        return
      }
      if (!this.masterInfo.familyAssets) {
        this.$toast('请选择站长家庭总资产')
        return
      }
      if (!this.masterInfo.familyAnnualIncome) {
        this.$toast('请选择站长家庭年收入')
        return
      }
      if (!this.masterInfo.familyIncomeSource) {
        this.$toast('请选择站长家庭收入来源')
        return
      }
      if (!this.masterInfo.relaInVillageOffice) {
        this.$toast('请选择家属是否在村委、乡镇任职')
        return
      }
      if (!this.masterInfo.headOfCivil) {
        this.$toast('请选择家属任民间组织负责人')
        return
      }
      // console.log(this.masterInfo.otherInfo)
      // if (this.masterInfo.otherInfo.length == 0) {
      //   this.$toast('其他信息不能为空')
      //   return
      // }
      if (this.pageInfoShowVal.isRaise == null) {
        this.$toast('请选择是否有非法集资、民间借贷等行为')
        return
      }
      if (this.pageInfoShowVal.isCrime == null) {
        this.$toast('请选择是否有公安机关不良记录或犯罪记录')
        return
      }
      if (this.pageInfoShowVal.isBet == null) {
        this.$toast('请选择是否参与赌博活动')
        return
      }
      if (this.pageInfoShowVal.isBadCredit == null) {
        this.$toast('是否有不良征信记录')
        return
      }
      if (this.pageInfoShowVal.isRaise || this.pageInfoShowVal.isCrime || this.pageInfoShowVal.isBet || this.pageInfoShowVal.isBadCredit) {
        this.$dialog.alert({
          title: '提示',
          message: '站长候选人不符合选用标准，无法完成建站申请',
          confirmButtonText: '我知道了'
        })
        return
      }

      let invesJson = {
        "isRaise": "NO",
        "isCrime": "NO",
        "isBet": "NO",
        "isBadCredit": "NO"
      }
      let data = {
        stationNo: this.$route.query.stationNo,
        surveyItem: JSON.stringify(invesJson),
      }

      let obj = Object.assign(data, this.masterInfo)
      let params = {}
      for (let key in obj) {
        if (obj[key] != null && obj[key] != '' && obj[key] != undefined) {
          params[key] = obj[key]
        }
      }
      if (params.otherInfo) {
        params.otherInfo = params.otherInfo.toString()
      } else {
        params.otherInfo = ''
      }
      saveMasterInfo(params).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp))
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            common.setItem('masterNo', data.uniqueNo)
            this.$router.push({
              name: 'stationAgentScore',
              query: {
                score: JSON.stringify(data),
                masterName: this.masterInfo.name
              }
            })
          } else {
            this.$toast(data.businessMessage)
          }
        } else {
          this.$toast(res.message)
        }
      })
    },
    queryPageInfo () {
      let para = {
        idCard: this.masterInfo.idCard
      }
      getIdCardExist(para).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            let json = data
            if (json.idCardExist != 0 && this.oldIDCard != this.masterInfo.idCard) {
              this.$toast('该身份证号已存在,请重新输入')
              this.masterInfo.idCard = '';
            }
          }
        }
      })
    },
    // 获取站长信息
    getMasterInfo () {
      getMasterInfoByNo({ stationNo: this.$route.query.stationNo }).then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            this.setMastrInfo(data.data);
          } else {
            // this.$toast(data.businessMessage)
          }
        }
      })
    },
    setMastrInfo (masterInfo) {
      var infoArray = ['idCard', 'name', 'phoneNo', 'isLocalResidence', 'liveVillage', 'partyMember', 'villageCadre', 'linkmanName', 'linkmanPhone', 'professionHistory', 'relaInVillageOffice'];
      this.setInputInfo(infoArray, masterInfo);
      this.oldIDCard = this.masterInfo.idCard;
      var selectInfoArray = ['education', 'marital', 'licenseQualify', 'linkmanRelation', 'familyAssets', 'familyAnnualIncome', 'familyIncomeSource', 'headOfCivil', 'familyAssets', 'familyAssets'];
      for (var nameString of selectInfoArray) {
        this.setSelectedInfo(this.selectList[nameString], masterInfo[nameString], nameString);
      }
      this.masterInfo.otherInfo = masterInfo.otherInfo.split(',');
      
      this.pageInfoShowVal.isRaise = false
      this.pageInfoShowVal.isCrime = false
      this.pageInfoShowVal.isBet = false
      this.pageInfoShowVal.isBadCredit = false    
    },
    setInputInfo (infoArray, itemsDic) {
      console.log('infoArray', infoArray)
      for (var obj of infoArray) {
        this.masterInfo[obj] = itemsDic[obj].toString();
        console.log('obj', obj, this.masterInfo[obj])
      }
    },
    setSelectedInfo (array, keyString, name) {
      console.log(name);
      for (let oneDict of array) {
        console.log(array);
        if (oneDict.dictKey == keyString) {
          this.pageInfoShowVal[name] = oneDict.dictValue;
          this.masterInfo[name] = keyString;
        }
      }
      return '';

    },
    // 获取配置文字
    getValue (array, keyString) {
      console.log(array, keyString);
      for (let oneDict of array) {
        if (oneDict.dictKey == keyString) {
          return oneDict.dictValue;
        }
      }
      return '';
    },
    // 获取站长配置项
    getMasterDicInfo () {
      getDictByPage('masterPage').then(res => {
        if (res.code === 200) {
          let data
          if (res.encryptedStatus === '1') {
            data = JSON.parse(common.decrypt(res.data, res.timestamp));
          } else {
            data = res.data
          }
          if (data.businessCode === 200) {
            let json = JSON.parse(data.data)
            this.masterDicInfo = json;
            console.log(this.masterInfo)
            // 获取站长信息
            this.getMasterInfo();
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.newSiteWarpZZ {
  background: #f5f5f5;
  padding-bottom: 1.5rem;
  overflow-x: hidden;
}
.flex {
  display: flex;
}
.mt2 {
  margin-top: 0.2rem;
}
.img-icon{
  width:0.4rem;
}
.title {
  text-align: left;
  background: #ffffff;
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #333333;
  font-weight: bold;
  font-size: 15px;
  margin-top: 0.2rem;
}
.nextBtn {
  width: 100%;
  padding: 0.24rem;
  background: #f7f8f9;
  position: absolute;
  bottom: 0;
  left: 0;
}
.btn-active {
  height: 0.98rem;
  line-height: 0.98rem;
  text-align: center;
  background: #3f7c53;
  border-radius: 0.08rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  opacity: 1;
}
.pl15 {
  padding: 0.1rem 0.3rem;
  background: #fff;
}
.van-checkbox {
  margin: 0.3rem 0;
}
div.title:before {
  width: 10px;
  height: 10px;
  color: #3f7c53;
  content: "| ";
}
</style>
<style>
.van-picker__confirm {
  color: #3f7c53;
}
.van-picker__cancel {
  color: #3f7c53;
}
.newSiteWarpZZ input {
  text-align: right;
}
.newSiteWarpZZ .nativeRadio {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.newSiteWarpZZ .startRadio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.2rem 0;
}
.nativeRadio .van-radio {
  margin-left: 10px;
}
.otherRadio {
  padding: 0.05rem 0.3rem;
  background: #fff;
}
.otherRadio .van-radio {
  margin-top: 15px;
}
.startRadio .van-radio {
  margin-right: 15px;
}
.van-checkbox__label {
  font-size: 15px;
}
.pl15 p {
  font-size: 15px;
}
.van-radio__label {
}
.newSiteWarpZZ .van-radio {
  font-size: 15px;
}
.newSiteWarpZZ .van-picker__confirm {
  color: #3f7c53;
}
.newSiteWarpZZ .van-picker__cancel {
  color: #3f7c53;
}
.newSiteWarpZZ .van-tab__text {
  font-size: 15px;
}
.newSiteWarpZZ .van-cell {
  min-height: 48px;
}
.newSiteWarpZZ .van-cell__title {
  font-size: 15px;
  color: #333333;
}
.newSiteWarpZZ .van-field__control {
  font-size: 15px;
}
</style>
